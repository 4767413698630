import DefaultHeader from '@/modules/Dashboard/DefaultHeader.vue';
import requireRoles from '@/routes/requireRoles';

const User = () => import(/* webpackChunkName: "pages" */ 'src/modules/Dashboard/Pages/UserProfile.vue');
const UserCreate = () => import(/* webpackChunkName: "pages" */ 'src/modules/Dashboard/Users/pages/Create.vue');
const UserEdit = () => import(/* webpackChunkName: "pages" */ 'src/modules/Dashboard/Users/pages/Edit.vue');
const Users = () => import(/* webpackChunkName: "pages" */ 'src/modules/Dashboard/Users/pages/Index.vue');

export default [
  {
    path: 'user',
    name: 'user',
    components: { default: User, header: DefaultHeader },
  },
  {
    path: 'users/create',
    name: 'users.create',
    components: { default: UserCreate, header: DefaultHeader },
    beforeEnter: requireRoles,
  },
  {
    path: 'users/edit/:userId',
    name: 'users.edit',
    components: { default: UserEdit, header: DefaultHeader },
    beforeEnter: requireRoles,
  },
  {
    path: 'users',
    name: 'users',
    components: { default: Users, header: DefaultHeader },
    beforeEnter: requireRoles,
  },
];
