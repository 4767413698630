import AuthLayout from '@/modules/Dashboard/Auth/AuthLayout.vue';

const Login = () => import(/* webpackChunkName: "auth" */ '@/modules/Dashboard/Auth/pages/Login.vue');

export default {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
  ],
};
