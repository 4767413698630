var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.user)?_c('user-menu',{attrs:{"title":_vm.user.email}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'now-ui-icons design_app',
          path: '/dashboard'
        }}}),(_vm.$store.getters.roles.includes('ROLE_SUPERADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'now-ui-icons users_circle-08',
          path: '/users'
        }}}):_vm._e(),(_vm.$store.getters.roles.includes('ROLE_SUPERADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Users Create',
          icon: 'now-ui-icons users_circle-08',
          path: '/users/create'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'stores',
          icon: 'now-ui-icons business_bank',
          path: '/stores'
        }}}),(_vm.$store.getters.roles.includes('ROLE_SUPERADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Store Create',
          icon: 'now-ui-icons ui-1_simple-add',
          path: '/stores/create'
        }}}):_vm._e(),(_vm.$store.getters.store)?_c('sidebar-item',{attrs:{"link":{
          name: 'Feed',
          icon: 'now-ui-icons education_paper',
          path: '/feed'
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }