export default (value, decimals, options) => {
  if (typeof value === 'undefined') return '';

  if (decimals !== undefined) {
    options.minimumFractionDigits = decimals;
    options.maximumFractionDigits = decimals;
  }

  return value.toLocaleString('nl-NL', options);
};
