<template>
  <div class="user user__menu">
    <div class="photo">
      <i class="now-ui-icons users_single-02"></i>
      <p>users_single-02</p>
    </div>
    <div class="info">
      <router-link :to="{ name: 'user' }">
        <span>{{ title }}</span>
      </router-link>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'UserMenu',
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  };
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
