export default {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, { userData, token }) {
    state.status = 'success';
    state.user = userData;
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.user = null;
    state.store = null;
  },
  set_store(state, storeData) {
    state.store = storeData;
  },
  set_user(state, user) {
    state.user = user;
  },
};
