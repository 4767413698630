<template>
  <div
    class="form-check"
    :class="[{ disabled: disabled }, inlineClass]">
    <label
      :for="cbId"
      class="form-check-label">
      <input
        :id="cbId"
        v-model="model"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled">
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
  export default {
    name: 'NCheckbox',
    model: {
      prop: 'checked',
    },
    props: {
      checked: {
        type: [Array, Boolean],
        default: null,
      },
      disabled: Boolean,
      inline: Boolean,
      hasError: Boolean,
    },
    data() {
      return {
        cbId: '',
        touched: false,
      };
    },
    computed: {
      model: {
        get() {
          return this.checked;
        },
        set(check) {
          if (!this.touched) {
            this.touched = true;
          }
          this.$emit('input', check);
        },
      },
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`;
        }
        return ``;
      },
    },
    created() {
      this.cbId = Math.random()
        .toString(16)
        .slice(2);
    },
  };
</script>
