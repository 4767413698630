<template>
  <div v-click-outside="closeModal">
    <fg-input
      v-model="search"
      placeholder="Search..."
      addon-right-icon="now-ui-icons ui-1_zoom-bold"
      @focus="modal = true" />
    <div
      v-show="items && modal"
      class="el-select-dropdown el-popper"
      x-placement="bottom">
      <div class="popper__arrow"></div>
      <div class="el-scrollbar">
        <div class="el-select-dropdown__wrap el-scrollbar__wrap">
          <ul class="el-scrollbar__view el-select-dropdown__list">
            <li
              v-for="item in items"
              :key="item.id"
              class="el-select-dropdown__item select-default"
              :class="{ 'checked': isSelected(item.id) }"
              @click="clickHandler(item)">
              {{ listLabel(item) }}
            </li>
            <li v-observe-visibility="handleInfiniteScroll"></li>
            <li
              v-if="noResults"
              class="el-select-dropdown__item select-default">
              No results
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      selectedItems: {
        type: [Array, Object],
        default: () => [],
      },
      setItem: {
        type: Function,
        required: true,
      },
      gqlQuery: {
        type: Function,
        required: true,
      },
      fetchMore: {
        type: Function,
        required: true,
      },
      result: {
        type: [String, Array],
        required: true,
      },
    },
    data() {
      return {
        items: [],
        modal: false,
        noResults: false,

        search: '',
        awaitingSearch: false,

        showLoadMoreButton: true,
        page: 1,
        lastPage: null,
      };
    },
    watch: {
      search: {
        handler() {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              if (this.search.length > 0) {
                this.setAllItems(this.search);
              } else {
                this.showLoadMoreButton = true;
                this.page = 1;
                this.setAllItems();
              }

              this.awaitingSearch = false;
            }, 300); // 300 milisec delay so you don't catch every input
          }
          this.awaitingSearch = true;
        },
      },
    },
    created() {
      this.setAllItems();
    },
    methods: {
      listLabel(result) {
        if (Array.isArray(this.result)) {
          let label = '';

          for (let i = 0; i < this.result.length; i += 1) {
            if (i > 0) {
              label += ' ';
            }

            label += result[this.result[i]];
          }

          return label;
        }

        return result[this.result];
      },
      handleInfiniteScroll(isVisible) {
        if (!isVisible || !this.showLoadMoreButton) return;
        this.loadMore();
      },
      clickHandler(item) {
        this.setItem(item);
        this.search = '';
      },
      isSelected(id) {
        if (!this.selectedItems) return false;

        if (Array.isArray(this.selectedItems)) {
          return this.selectedItems.some(item => item.id === id);
        }

        return this.selectedItems.id === id;
      },
      async setAllItems(search) {
        this.results = false;

        try {
          const variables = {};

          search ? variables.search = search : variables.page = this.page;

          this.gqlQuery(variables);
        } catch (error) {
          console.log(error);
        }
      },
      async loadMore() {
        this.page += 1;

        const oldItems = this.items;

        try {
          const variables = {
            page: this.page,
          };

          await this.fetchMore(variables, oldItems);
        } catch (error) {
          console.log(error);
        }
      },
      closeModal() {
        this.modal = false;
        this.search = '';
      },
    },
  };
</script>

<style scoped>
.el-popper {
  min-width: 200px;
  position: absolute;
  transform-origin: center top;
  z-index: 2001 !important;
}

.popper__arrow {
  left: 35px !important;
}

.checked {
  pointer-events: none;
}

.checked::after {
  font-family: Nucleo Outline, sans-serif;
  content: "\ea22";
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 0;
  top: 0;
  color: #18ce0f;
  font-size: 11px;
}

.el-select-dropdown__wrap {
  max-width: 300px !important;
}
</style>
