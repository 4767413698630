import number from './number';

export default value => {
  const float = parseFloat(value);
  const options = {
    style: 'currency',
    currency: 'EUR',
  };
  return number(float, 2, options);
};
