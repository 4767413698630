import DefaultHeader from '@/modules/Dashboard/DefaultHeader.vue';

const Feed = () => import(/* webpackChunkName: "feed" */ 'src/modules/Dashboard/Feed/pages/Index.vue');

export default [
  {
    path: 'feed',
    name: 'feed',
    components: { default: Feed, header: DefaultHeader },
  },
];
