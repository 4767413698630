import DefaultHeader from '@/modules/Dashboard/DefaultHeader.vue';

const Stores = () => import(/* webpackChunkName: "store" */ 'src/modules/Dashboard/Stores/pages/Index.vue');
const StoresCreate = () => import(/* webpackChunkName: "store" */ 'src/modules/Dashboard/Stores/pages/Create.vue');
const StoresShow = () => import(/* webpackChunkName: "store" */ 'src/modules/Dashboard/Stores/pages/Show.vue');
const StoresEdit = () => import(/* webpackChunkName: "store" */ 'src/modules/Dashboard/Stores/pages/Edit.vue');

export default [
  {
    path: 'stores',
    name: 'stores',
    components: { default: Stores, header: DefaultHeader },
  },
  {
    path: 'stores/create',
    name: 'stores.create',
    components: { default: StoresCreate, header: DefaultHeader },
  },
  {
    path: 'stores/:storeId',
    name: 'stores.show',
    components: { default: StoresShow, header: DefaultHeader },
  },
  {
    path: 'stores/edit/:storeId',
    name: 'stores.edit',
    components: { default: StoresEdit, header: DefaultHeader },
  },
];
