import axios from 'axios';
import qs from 'qs';

const http = axios.create({
  baseURL: 'http://users.test/api/',
  paramsSerializer: params => qs.stringify(params, {
    skipNulls: true,
  }),
});

export default http;
