import axios from 'axios';
import GetStoreById from '@/graphql/stores/queries/GetStoreById.gql';
import GetCurrentUser from '@/graphql/users/queries/GetCurrentUser.gql';
import { apolloClient } from '@/vue-apollo';
import router from '@/routes';

export default {
  async login({ commit }, user) {
    commit('auth_request');

    try {
      const response = await axios({ url: 'https://dev.api.v3.gmu.online/authentication_token', data: user, method: 'POST' });

      const token = response.data.token;
      const userData = user;

      localStorage.setItem('token', token);
      axios.defaults.headers.common.Authorization = token;
      commit('auth_success', { userData, token });
    } catch (error) {
      commit('auth_error');
      localStorage.removeItem('token');
      localStorage.removeItem('store_id');
    }
  },
  async logout({ commit }) {
    commit('logout');
    localStorage.removeItem('token');
    localStorage.removeItem('store_id');
    delete axios.defaults.headers.common.Authorization;
  },
  async fetchUser({ commit, dispatch }) {
    try {
      const result = await apolloClient.query({
        query: GetCurrentUser,
        manual: true,
        fetchPolicy: 'no-cache',
      });

      commit('set_user', result.data.currentUser);
    } catch (error) {
      const result = error.networkError.result;
      if (result.code === 401 && result.message === 'Expired JWT Token') {
        await dispatch('logout');
        await router.push({ name: 'Login' });
      }
    }
  },
  async fetchStore({ commit }, id) {
    try {
      const result = await apolloClient.query({
        query: GetStoreById,
        variables: {
          id,
        },
        manual: true,
      });

      localStorage.setItem('store_id', result.data.store.id);
      commit('set_store', result.data.store);
    } catch (error) {
      console.log(error);
    }
  },
};
