// configure router
import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '@/routes/home';
import authPages from '@/modules/Dashboard/Auth/routes';
import dashboardPages from '@/routes/dashboard';
import catchAllRoute from '@/routes/catchAllRoute';

Vue.use(VueRouter);

const routes = [
  home,
  authPages,
  dashboardPages,
  catchAllRoute,
];

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
});

export default router;
