import DashboardLayout from '@/modules/Dashboard/Layout/DashboardLayout.vue';
import storesRoutes from '@/modules/Dashboard/Stores/routes/index';
import usersRoutes from '@/modules/Dashboard/Users/routes/index';
import feedRoutes from '@/modules/Dashboard/Feed/routes/index';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/modules/Dashboard/Layout/Dashboard.vue');
const DashboardHeader = () => import(/* webpackChunkName: "dashboardHeader" */ '@/modules/Dashboard/Layout/DashboardHeader.vue');

export default {
  path: '/',
  component: DashboardLayout,
  redirect: '/dashboard',
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      components: { default: Dashboard, header: DashboardHeader },
    },
    ...storesRoutes,
    ...usersRoutes,
    ...feedRoutes,
  ],
};
