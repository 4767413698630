import number from './number';
import money from './money';
import date from './date';
import capitalize from './capitalize';

export default {
  install(Vue) {
    Vue.filter('number', number);
    Vue.filter('money', money);
    Vue.filter('date', date);
    Vue.filter('capitalize', capitalize);
  },
};
