<template>
  <div
    class="card"
    :class="{ 'card-plain': plain }">
    <h6
      v-if="$slots.category || category"
      class="card-category">
      {{ category }}
    </h6>
    <div
      v-if="$slots.image"
      class="card-image">
      <slot name="image"></slot>
    </div>
    <div
      v-if="$slots.header || title"
      class="card-header"
      :class="headerClasses">
      <slot name="header">
        <h1
          v-if="title"
          class="card-title">
          {{ title }}
        </h1>
        <h3
          v-if="subTitle"
          class="card-category">
          {{ subTitle }}
        </h3>
        <p
          v-if="description"
          class="card-description">
          {{ description }}
        </p>
      </slot>
    </div>
    <slot name="raw-content"></slot>
    <div
      v-if="$slots.default"
      class="card-body"
      :class="cardBodyClasses">
      <slot></slot>
    </div>
    <hr v-if="$slots.footer && !noFooterLine">
    <div
      v-if="$slots.footer"
      class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Card',
    props: {
      title: {
        type: String,
        default: '',
      },
      subTitle: {
        type: String,
        default: '',
      },
      category: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      noFooterLine: Boolean,
      plain: Boolean,
      cardBodyClasses: {
        type: [String, Object, Array],
        default: null,
      },
      headerClasses: {
        type: [String, Object, Array],
        default: null,
      },
    },
  };
</script>
<style></style>
