import store from '@/store';

export default async function requireRoles(to, from, next) {
  try {
    await store.dispatch('fetchUser', localStorage.getItem('token'));

    if (store.getters.roles.includes('ROLE_SUPERADMIN')) {
      next();
    } else {
      next('/dashboard');
    }
  } catch (error) {
    console.log(error);
  }
}
