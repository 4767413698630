var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[
    { 'input-group': _vm.hasIcon },
    { 'has-danger': _vm.error },
    { 'input-group-focus': _vm.focused },
    { 'has-label': _vm.label || _vm.$slots.label },
    { 'has-success': !_vm.error && _vm.touched },
    { 'addon': _vm.addonRightIcon || _vm.addonLeftIcon }
  ]},[_vm._t("label",[(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]),_vm._t("addonLeft",[(_vm.addonLeftIcon)?_c('div',{staticClass:"input-group-addon input-group-prepend"},[_c('i',{class:_vm.addonLeftIcon})]):_vm._e()]),_vm._t("default",[_c('input',_vm._g(_vm._b({staticClass:"form-control",class:[{ valid: _vm.value && !_vm.error }, _vm.inputClasses],attrs:{"autocomplete":"one-time-code","aria-describedby":"addon-right addon-left"},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]),_vm._t("addonRight",[(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-addon input-group-append"},[_c('i',{class:_vm.addonRightIcon})]):_vm._e()]),_vm._t("infoBlock"),_vm._t("helpBlock",[(_vm.error)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }