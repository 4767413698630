/*!

=========================================================
* Vue Now UI Dashboard PRO - v1.2.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-now-ui-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be
included in all copies or substantial portions of the Software.

 */

import Vue from 'vue';
import { Select } from 'element-ui';
import notifyVue from '@/globalMethods/notify';
import clickOutside from '@/directives/click-ouside';
import routerBeforeEach from '@/routes/beforeEach';
import router from '@/routes';
import VueObserveVisibility from 'vue-observe-visibility';
import store from './store/index';
import http from './http';
import DashboardPlugin from './dashboard-plugin';

// Plugins
import App from './App.vue';
import filters from './filters';

import { createProvider } from './vue-apollo';
import i18n from './i18n';

// plugin setup
Vue.use(filters);
Vue.use(DashboardPlugin);
Vue.use(Select);
Vue.use(VueObserveVisibility);
Vue.prototype.$http = http;
const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token;
}

Vue.directive('click-outside', clickOutside);

routerBeforeEach(router, store);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  methods: { notifyVue },
  render: h => h(App),
  apolloProvider: createProvider(),
  router,
  i18n,
  store,
});
