function notifyVue(type, message) {
  this.$notify({
    message,
    timeout: 3000,
    icon: 'now-ui-icons ui-2_like',
    type,
  });
}

export default notifyVue;
