<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div
        class="navbar-toggle"
        :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click.native="toggleSidebar" />
      </div>
      <div class="navbar-brand d-flex flex-column">
        <span>{{ $route.name }}</span>
        <span
          v-if="$store.getters.store"
          style="font-weight: 600">
          {{ $store.getters.store.name }}
        </span>
      </div>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNavbar">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template slot="navbar-menu">
      <form>
        <div class="input-group no-border">
          <auto-complete
            ref="autoComplete"
            :selected-items="$store.getters.store"
            :set-item="setStore"
            :gql-query="storeQuery"
            :fetch-more="fetchMore"
            result="name" />
        </div>
      </form>

      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'user' }">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </router-link>
        </li>
        <li>
          <a
            class="navbar-brand ml-1"
            @click.prevent="logout">
            Logout
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components';
  import AutoComplete from '@/components/AutoComplete.vue';
  import GetAllStores from '@/graphql/stores/queries/GetAllStores.gql';

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      AutoComplete,
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
        queryName: 'setAllStores',
      };
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
    },
    methods: {
      async storeQuery(variables) {
        await this.$apollo.addSmartQuery('setAllStores', {
          query: GetAllStores,
          variables,
          manual: true,
          result({ data, loading }) {
            if (!loading) {
              this.$refs.autoComplete.items = data.stores.collection;
              this.$refs.autoComplete.lastPage = data.stores.paginationInfo.lastPage;

              if (!data.stores.collection.length) {
                this.$refs.autoComplete.noResults = true;
              }
            }
          },
        });
      },
      async fetchMore(variables, oldData) {
        await this.$apollo.queries.setAllStores.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.$refs.autoComplete.items = [...oldData, ...fetchMoreResult.stores.collection];

            if (this.$refs.autoComplete.page === this.$refs.autoComplete.lastPage) {
              this.$refs.autoComplete.showLoadMoreButton = false;
            }
          },
        });
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      async setStore(store) {
        await this.$store.dispatch('fetchStore', store.id);
        this.$root.notifyVue('info', `${store.name} is selected`);

        this.$refs.autoComplete.modal = false;
      },
      async logout() {
        await this.$store.dispatch('logout');
        await this.$router.push({ name: 'Login' });
      },
    },
  };
</script>
