import moment from 'moment';

moment.locale('nl');

export default (timestamp, format) => {
  const date = moment(timestamp, ['X', 'YYYY-MM-DD HH:mm:ss']);

  if (!date.isValid()) return 'n.n.b.';

  if (format) {
    return date.format(format);
  }

  const hourAgo = moment().subtract(1, 'hour');
  if (date.isAfter(hourAgo)) {
    return date.fromNow();
  }

  const weekAgo = moment().subtract(6, 'days');
  if (date.isBefore(weekAgo)) {
    return date.format('LL');
  }

  return date.calendar();
};
