<template>
  <router-view />
</template>

<script>
  export default {
    async created() {
      await this.$http.interceptors.response.use(undefined, error => {
        if (error.status === 401 && error.config) {
          this.$store.dispatch('logout');
        }
      });

      if (localStorage.getItem('token')) {
        await this.$store.dispatch('fetchUser', localStorage.getItem('token'));
      }

      if (localStorage.getItem('store_id')) {
        await this.$store.dispatch('fetchStore', localStorage.getItem('store_id'));
      }
    },
  };
</script>
