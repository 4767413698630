<template>
  <div
    class="sidebar"
    :data-color="backgroundColor">
    <div class="logo">
      <a
        href="http://www.creative-tim.com"
        class="simple-text logo-mini">
        <div class="logo-image">
          <img :src="logo">
        </div>
      </a>

      <a
        href="http://www.creative-tim.com"
        class="simple-text logo-normal">
        {{ title }}
      </a>
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-outline-white btn-icon btn-round"
          @click="minimizeSidebar">
          <i
            class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
          <i
            class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
        </button>
      </div>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link">
            <sidebar-item
              v-for="(subLink, subIndex) in link.children"
              :key="subLink.name + subIndex"
              :link="subLink" />
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Sidebar',
    props: {
      title: {
        type: String,
        default: 'GMU V3',
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: value => {
          const acceptedValues = [
            '',
            'blue',
            'azure',
            'green',
            'orange',
            'red',
            'purple',
            'black',
          ];
          return acceptedValues.indexOf(value) !== -1;
        },
      },
      logo: {
        type: String,
        default: 'img/icon-vue.png',
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
      },
      autoClose: {
        type: Boolean,
        default: true,
      },
    },
    provide() {
      return {
        autoClose: this.autoClose,
      };
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    },
    methods: {
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      },
    },
  };
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
