<template>
  <div
    class="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />
    <side-bar>
      <template slot="links">
        <user-menu
          v-if="user"
          :title="user.email" />
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'now-ui-icons design_app',
            path: '/dashboard'
          }" />
        <sidebar-item
          v-if="$store.getters.roles.includes('ROLE_SUPERADMIN')"
          :link="{
            name: 'Users',
            icon: 'now-ui-icons users_circle-08',
            path: '/users'
          }" />
        <sidebar-item
          v-if="$store.getters.roles.includes('ROLE_SUPERADMIN')"
          :link="{
            name: 'Users Create',
            icon: 'now-ui-icons users_circle-08',
            path: '/users/create'
          }" />
        <sidebar-item
          :link="{
            name: 'stores',
            icon: 'now-ui-icons business_bank',
            path: '/stores'
          }" />
        <sidebar-item
          v-if="$store.getters.roles.includes('ROLE_SUPERADMIN')"
          :link="{
            name: 'Store Create',
            icon: 'now-ui-icons ui-1_simple-add',
            path: '/stores/create'
          }" />
        <sidebar-item
          v-if="$store.getters.store"
          :link="{
            name: 'Feed',
            icon: 'now-ui-icons education_paper',
            path: '/feed'
          }" />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar />
      <router-view name="header" />

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar">
        <zoom-center-transition
          :duration="200"
          mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import UserMenu from '@/modules/Dashboard/Layout/Extra/UserMenu.vue';

  import { ZoomCenterTransition } from 'vue2-transitions';
  import { mapGetters } from 'vuex';
  import TopNavbar from './TopNavbar.vue';
  import ContentFooter from './ContentFooter.vue';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      UserMenu,
      ZoomCenterTransition,
    },
    computed: {
      ...mapGetters(['user']),
    },
    mounted() {
      const docClasses = document.body.classList;
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
    },
  };
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
